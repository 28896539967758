  
  .animate {
    transition:  opacity 0.35s linear 2s;
    padding: 12px;
    border-radius: 58%;
    background-color: #F76A27;
    color:white;
    width: 60px;
    cursor: pointer;
    margin: auto;
  }

  .animate.grow {
    opacity: 1;
  }
  .normal{
    opacity: 0;
  }
  .icon{
    margin: 20px 8px 4px auto;
    width: 20%;
    text-align: center;
  }

