/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

/* Home Page */

.active_nav_bar {
  border-color: #0dbaff !important;
}

.changePage_bg {
  background: linear-gradient(90deg,#0d9ec2,#085f88);
}

@media screen and (max-width: 1062px) {
  .waviy {
    position: relative !important;
    -webkit-box-reflect: initial !important;
    font-size: 30px !important;
    font-family: "PT Sans", sans-serif !important;
    text-align: center !important;
  }
  .waviy_padding {
    padding: 1rem !important;
  }
}

.waviy {
  position: relative;
  -webkit-box-reflect: below -5px linear-gradient(transparent, rgba(0, 0, 0, 0.1));
  font-size: 60px;
  font-family: "PT Sans", sans-serif;
  text-align: center;
}

.waviy_padding {
  padding: 3rem;
}

a {
  color: inherit !important;
  text-decoration: inherit !important;
}

.homepage2text {
  font-size: larger !important;
  font-family: "PT Sans", sans-serif;
  animation: fadeInRight 2s ease-in-out;
}

.homepage2text p {
  font-weight: 400 !important;
}

.homepage2 {
  animation: fadeInLeft 2s ease-in-out;
}

.modal-header{
  background-color: #0DBAFF;
}

.modal-title{
  color: #ffffff;
}

/* Onboarding commitment */
.onboard_sub_heading {
  font-weight: 700;
  font-family: "PT Sans", sans-serif !important;
  font-size: 25px;
}

.onboard_paragraph {
  font-weight: 500;
  color: white;
  font-family: "PT Sans", sans-serif;
  padding-top: 9px;
}

.onboard_bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #d4e7f8 !important;
}

/* Customer Voice */
.customerVoice_cardTitle {
  font-weight: bold;
  font-family: "PT Sans", sans-serif !important;
}
.customer-img {
  width: 35% !important;
  margin-bottom: -1% !important;
  margin-top: 4% !important;
}
.customerVoice_bg {
  background-color: white;
}
.customer_card {
  border-radius: 10% 42% 10% 30%/ 8% 19% 10% 15% !important ;
  background-color: #4cc5d9 !important;
}
.customer_cards {
  /* border-radius: 10% 0% 0% 10%/ 10% 0% 0% 10%!important ; */
  border-right: 5px solid #4cc5d9 !important;
  top: 30% !important;
  background-color: #f6f8fa !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
  margin-bottom: 10px;
  margin-left: 10px;
  position: relative!important;
}
.customer_card_height {
  max-height: 145px;
  min-height: 145px;
}
.customer_card_content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.customer_control_left {
  position: absolute !important;
  top: 50% !important;
  left: 40%;
}
.customer_control_right {
  position: absolute !important;
  top: 50% !important;
  left: 40%;
}
.customer_hr {
  background-color: #243e62;
}

/* Our Presence */
.ourpresence_bg {
  background: linear-gradient(90deg,#0d9ec2,#085f88);
  background-size: cover;
}
.ourpresence_text {
  top: 3% !important;
  color: white !important;
}
.ourpresence_text_style {
  width: 350px !important;
  font-size: 35px;
  font-family: "PT Sans", sans-serif !important;
}
.ourpresence_flag {
  width: 45px !important;
  float: left;
  padding: 5px !important;
  top: 15% !important;
  margin: 10px;
}
.ourpresence_header {
  font-family: "PT Sans", sans-serif !important;
  color: white !important;
  font-size: 70px;
}
.ourpresence_lion {
  width: 280px !important;
  float: left;
  padding: 5px !important;
  top: 15% !important;
  margin: 10px;
}
/* .Made_In_India{
  width: 42%!important;
  margin-top: 5%;
} */

@media screen and (max-width: 992px) {
  .ourpresence_text_style {
    font-size: 30px;
  }
  .ourpresence_flag {
    width: 35px !important;
    margin: 3px 3px;
  }
  .ourpresence_header {
    font-size: 35px !important;
  }
  .ourpresence_text {
    left: 5% !important;
  }  
  .ourpresence_lion {
    width: 180px !important;
    margin: 3px 3px;
  }
}

@media screen and (max-width: 768px) {
  .ourpresence_text_style {
    font-size: 18px;
  }
  .ourpresence_flag {
    width: 35px !important;
    margin: 3px 3px;
  }
  .ourpresence_header {
    font-size: 30px !important;
  }
  .ourpresence_text {
    left:0% !important;
  }
  .ourpresence_lion {
    width: 180px !important;
    margin: 3px 3px;
  }
}

@media screen and (max-width: 425px) {
  .ourpresence_header {
    font-size: 15px !important;
  }
  .ourpresence_text_style {
    font-size: 10px !important;
  }
  .ourpresence_flag {
    width: 30px !important;
    margin: -2px auto;
  }
  .ourpresence_text {
    top: 27% !important;
  } 
  .ourpresence_lion {
    width: 100px !important;
    margin: 3px 3px;
  }
}

@media screen and (min-width: 425px) and (max-width: 600px) {
  .ourpresence_header {
    font-size: 20px !important;
  }
  .ourpresence_text_style {
    font-size: 15px !important;
  }
  .ourpresence_text {
    top: 18% !important;
  }
  .ourpresence_flag {
    width: 35px !important;
    margin: 3px 3px;
  }
 .ourpresence_lion {
    width: 100px !important;
    margin: 3px 3px;
  }
  
}

@media screen and (max-width: 375px) {
  .ourpresence_header {
    font-size: 15px !important;
  }
  .ourpresence_text_style {
    font-size: 10px !important;
  }
  .ourpresence_flag {
    width: 30px !important;
    margin: -2px auto;
  }
  .ourpresence_text {
    top: 23% !important;
    margin: 0px !important;
  }  
  .ourpresence_lion {
    width: 100px !important;
    margin: 3px 3px;
  }
}

.footer_color {
  text-align: justify;
  color: white !important;
  background-color: #222 !important;
  font-family: "PT Sans", sans-serif;
}
.footer_zingerTitle {
  width: 100% !important;
  text-align: justify !important;
}
.footer_icon a {
  color: #0f0e0e !important;
}
.appStore-img {
  width: 40%;
  border: 1px solid white;
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  .social_media_icons_xs {
    margin: auto !important;
  }
  .social_media_icons_mr_0 {
    margin-right: 5px !important;
  }
  .social_media_icons_m_0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .social_media_icons_ml_0 {
    margin-left: 5px !important;
  }
}

/* Features Page */
/* .background_img{
  width:100%;
  height: 100vh;

} */

 .background_imgg{
  width:100%;
  height: 100vh;

} 

.backgoundtextfeatures {
  position: absolute;
  top: 15%;
  left: 8%;
  font-family: "PT Sans", sans-serif;
  animation: fadeInRight 3s ease-in-out;
}

.background_heading {
  font-weight: bold;
}

.backgoundtextfeatures p {
  font-family: "PT Sans", sans-serif;
}

#sc {
  height: 98vh;
  overflow-y: scroll;
  display: block;
}
/* width */
#sc::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#sc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d4e7f8;
  border-radius: 10px;
}

/* Handle */
#sc::-webkit-scrollbar-thumb {
  background: #b9e4f7;
  border-radius: 10px;
}

/* Handle on hover */
#sc::-webkit-scrollbar-thumb:hover {
  background: #0dbaff;
}

#wrapper {
  display: flex;
  justify-content: left;
}
#wrapper.fix-bottom-VP #sc,
#wrapper.fix-top-VP #sc {
  position: fixed;
}
#wrapper.fix-bottom-VP #sc {
  bottom: 15px;
}
#wrapper.fix-top-VP #sc {
  top: 15px;
}
#wrapper.flex-bottom {
  align-items: flex-end;
}
#sidebar {
  position: relative;
  margin-top: 4rem;
}

.sidebar {
  border-left: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-top: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
  
}
.sidebar1 {
  border-bottom: 0px solid rgba(0, 0, 0, 0.125) !important;
  animation-duration: 5s;
  list-style: none;
  animation: fadeInRight 2s ease-in-out;
}
.feature_active_tab {
  color: white !important;
  background: #0dbaff !important;
  font-weight: bold !important;
  border-radius: 0% !important;
}

.feature_active_tab:hover {
  cursor: pointer !important;
}

.feature_tab:hover{
  cursor: pointer !important;
  background: #D4E7F8 !important;
}

.features-font-weight-bold {
  font-weight: 600;
}

/* Plans Page */
.plans_bg {
  background:linear-gradient(90deg,#0d9ec2,#085f88) !important;
}
.plansBtn {
  color: #000000;
  border: 2px solid #0dbaff !important;
  box-shadow: none;
  border-radius: 0.7rem;
  box-shadow: 0px 4px 12px #0003;
  margin-left: 1rem !important;
  display: block;
  padding: 0.5rem 1rem;
}
.plansBtn:hover {
  background: #0dbaff;
  color: #ffffff;
  border-radius: 0.7rem;
  margin-left: 1rem;
  cursor: pointer;
}
.plansBtnActive {
  background: #0dbaff;
  color: #ffffff !important;
  transition: transform 0.5s;
  transform: scale(1.12);
}

.pricingCard {
  height: 100%;
  width: 100%;
  background-color: #0dbaff !important;
}
.pricingright {
  margin-top: 5rem;
  margin-left: 16rem;
  font-size: large;
}
.pricingleft {
  margin-left: 7rem;
  margin-top: 5rem;
  font-size: large;
}
.pricingIcon {
  height: 32px;
  width: 100%;
  margin-left: -145px;
  margin-top: -31px;
  color: #0dbaff;
}
.pricingIconright {
  height: 32px;
  width: 100%;
  margin-top: -27px;
  margin-left: -231px;
  color: #0dbaff;
}
.pricingCardup {
  transform: rotate(-31deg);
  padding-bottom: 9px;
  padding-right: 90px;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}
.pricingCardborder {
  border-top: 2px solid #ffffff;
  transform: rotate(154deg);
  width: 120%;
  height: 100%;
  margin-left: -2rem;
}
.pricingCarddown {
  padding-top: 20px;
  text-align: center;
  transform: rotate(-31deg);
  font-weight: 700;
  padding-left: 30px;
  color: #ffffff;
}
.PricingContent {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
}
.pricingbtncolor {
  background: #0dbaff;
  color: #ffffff;
  border-radius: 0.7rem;
  margin-left: 1rem;
}

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
  .background_paragraph {
    font-size: 13px;
  }
  .background_heading {
    font-size: 20px;
  }
  .customer_cards{
    position: initial !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .pricing_div_right {
    margin-left: 40% !important;
  }
}

/* Contact Page */
.textandheader {
  font-family: "PT Sans", sans-serif !important;
}

.contact-bg {
  background-color: #fd7e141a;
}
.contact-form {
  background: #d4e7f8;
  padding: 20px;
  border-radius: 7px;
}
.iframeContact {
  border: 1px solid #e2bb2666;
}

.animationleft {
  animation: fadeInLeft 2s ease-in-out;
  font-weight: bold;
}
.animationRight {
  animation: fadeInRight 5s ease-in-out;
  font-weight: bold;
}

.tabcontent {
  border-radius: 5px;
  color: #0f0e0e;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
  animation: fadeInLeft 5s ease-in-out;
  background-color: #ffffff !important;
  padding: 16px;
}
@media screen and (max-width: 500px) {
  .tabcontent {
    height: 100%;
  }
  .background_paragraph {
    font-size: 10px !important;
  }
  .background_heading {
    font-size: 10px !important;
  }
  .backgoundtextfeatures {
    top: 6% !important;
  }
}
.tabcontent:hover {
  border-radius: 5px;
  -webkit-transform: scale(1.3);
  transform: scale(1.05);
  box-shadow: 0 0 0 0 rgb(185 228 247), 0 0px 5px 0 rgb(56 186 255);
}

.featureIcon {
  text-align: center !important;
  margin-top: 1rem;
}
.featureIcons {
  margin-top: 1rem;
  margin-left: 1rem;
}

body {background-color: #f6f8fa !important;}

.testimonals{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(#f8f9fa 0%, #f8f9fa 100%);
  outline: none;
  border-radius: 5%!important;
  border: 5px solid #e9ecef;
  transition: transform 0.5s;
}
.testimonals:hover {
  transform: scale(1.01);
}
.testimonalsIcon{
  margin-left: 36rem;
  margin-top: 2rem;
}
.testimonalsControlBtn{
  background-color: #0DBAFF !important;
}
.testimonalsHeader{
  /* margin-top: 10px; */
  text-align: center;
  display: inline-flex;
}
.testimonalsHeader img {
  width: 28px;
  /* margin-right: 15px;
  margin-left: 10px; */
  margin: 2px 10px;
  transition: transform 0.5s;
}
.testimonalsHeader img:hover {
  transform: scale(1.10);
}
@media screen and (max-width: 320px) {
  .testimonalsHeader {
    font-size: 16px;
  }
  .testimonalsHeader img {
    margin: -3px 8px;
    padding-right: 3px;
  }
}

/* captcha style */
.rnc {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 222px;
  background-color: #172a527d;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  /* margin: auto; */
}

.rnc-center .rnc{
  margin: auto !important;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 5px 0;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  height: 42px;
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 3px 6px;
  font-size: inherit;
  font-family: inherit;
}

.rating-card {
  border-left: 3px solid #4769ab7a;
}

.primaryLinkStyle{
  box-shadow: inset 0 0 5px #627ca0;
  color: white !important;
}

.ptSans{
  font-family: "PT Sans", sans-serif !important; 
}
.slick-arrow{
 display: none !important;
}
.slick-slider {
   cursor:  pointer !important;
}

.page__title-overlay:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,3,32,.6);
}

.section__title {
  font-size: 30px;
  color: #0e1133;
  text-transform: capitalize;
  z-index: 1;
  margin-bottom: 3px;
  border-left: 5px solid #2b4eff;
  padding: 10px 5px 0 15px;
}

.video_size {
  padding: 3rem;

}

@media screen and (max-width: 320px) {

  .video_size {
    padding: 0rem;
    margin-bottom: 30px;
  
  }
}

@media screen and (max-width: 375px) {
  .video_size {
    padding: 0rem;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 425px) {
  .video_size {
    padding: 0rem;
    margin-bottom: 30px;
  }
}


/* Feature Box
---------------------*/
.feature-box-1 {
  height: 212px;
  padding: 32px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  margin: 15px 0;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  top: 0;
}

.feature-box-1 * {
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}

.feature-box-1 .icon  {
  line-height: 70px;
  margin: 21px 120px 30px auto;
}

.feature-box-1 .icon img {
  line-height: 70px;
  width: fit-content;
  height: fit-content;
}

.feature-box-1 h5 {
  /* color: #20247b; */
  color:#0d9ec2;
  font-weight: 600;
}

.feature-box-1 p {
  margin: 0;
}

.feature-box-1:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-radius: 10px;
  width: 0;
  background:linear-gradient(90deg,#0d9ec2,#085f88) !important;
  z-index: -1;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}

.feature-box-1:hover {
  top: -5px;
}

.feature-box-1:hover h5 {
  color: #ffffff;
}

.feature-box-1:hover p {
  color: white;

}

.feature-box-1:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  left: 0;
  right: auto;
}

.section {
    padding: 100px 0;
    position: relative;
}

.section-title {
    padding-bottom: 45px;
}

.section-title h2 {
    font-weight: 700;
    color:#085f88;
    font-size: 45px;
    margin: 0 0 15px;
    border-left: 5px solid #085f88;
    padding-left: 15px;
}
/* .box_size{
  width: 50%;
} */

.side {
  -ms-flex: 30%; /* IE10 */
  flex: 30%;
  background-color: #f1f1f1;
  padding: 20px;
}

/* Feature header 
-------------------*/

.home-5-bg {
  position: relative;
  background-size: cover;
  height: 99vh;
  background-position: center center;
  border-bottom: 1px solid #eaeaea;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: #f5f5f5;
}

 .sections {
  position: relative;
}

.home-5-content {
z-index: 1;
position: relative;
}

.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.home-5-content {
  position: relative;
}

.text-white-70 {
color: white;
}

.f-15 {
  font-size: 15px;
  color: white;
  

} 

.image-bg{
  filter: brightness(30%);
  width: 100%;
  height: 100%;
}

.btn-primary{
  background-color: #085f88 !important;
  border-color: #085f88 !important;

}

.responsive_qr {
  margin-left: -10px;
}

@media screen and (max-width: 600px) {
  .responsive_qr {
    margin-left: 0; 
  }
}

.mobile_Text{
  color:#085f88;
}

.home_sub_heading {
  font-weight: 400;
  font-family: "PT Sans", sans-serif !important;
  font-size: 30px;
}


.circle{
  margin: auto;
  width: 50%;
  
}


/* .vl{
  border-left: 6px solid green;
  height: 500px;
  width: 10px;
  padding-left: 50px;
} */

.section-titlee {
  padding-bottom: 45px;
  /* float: right; */
  /* border-left: 6px solid #000000 ; */
}

.section-titlee h2 {
  font-weight: 700;
  color:#085f88;
  font-size: 45px;
  margin: 1px;

  /* border-left: 5px solid #085f88; */
  padding-left: 1px;
}
.topright {
  position: absolute;
  top: 271px;
  right: 16px;
  font-size: 16px;
}

.paraline {
  line-height: 200%;
}

.qr_img{
  margin-left:0px;
}

.inner-common{
  position: relative;
  display: inline-block;
  /* border: 12px solid #fff; */
} 
